<template>
    <div class="single-header">
        <secondary-header :title="lang('Genealogy')" backRoute="default" :menuButton="false"></secondary-header>

        <main>
            <div class="container">
                <div class="row justify-content-center digital-card">
                    <div class="col-lg-10 py-3 px-1">
                        <div class="d-flex mb-3 px-2">
                            <div class="d-flex">
                                <img :src="merchant.merchant_logo" alt="" class="logo m-auto" style="max-width: 100%; max-height: 7rem;">
                            </div>
                            <div class="flex-fill pl-2">
                                <h4 class="text-center ">{{ merchant.name }}</h4>
                                <input name="searching" id="searching" v-model="searching" class="form-control mb-2" placeholder="Name 1 / Name 2">
                            </div>
                        </div>
                        <div class="genealogy-list">
                            <div class="level-1">
                                <div class="card pr-4" :style="'background-color:'+ ((genealogies.level_1 && genealogies.level_1.is_current_user) ? genealogy_settings.color_self : genealogy_settings.color_level_1) +';'">
                                    <div v-if="genealogies.level_1" class="card-body p-2">
                                        <font-awesome-icon icon="genderless" size="2x" :class="'gender '+ genealogies.level_1.gender"></font-awesome-icon>
                                        <div v-for="(text_content, i) in genealogy_settings.text_contents" :key="i" :class="'text-'+ text_content.align">
                                            <div v-if="genealogies.level_1[text_content.text_left]" class="text-value">{{ genealogies.level_1[text_content.text_left] }}</div>
                                            <div v-if="genealogies.level_1[text_content.text_centre]" class="text-value">{{ genealogies.level_1[text_content.text_centre] }}</div>
                                            <div v-if="genealogies.level_1[text_content.text_right]" class="text-value">{{ genealogies.level_1[text_content.text_right] }}</div>
                                        </div>
                                    </div>
                                    <div v-else-if="!state.loading_resource" class="card-body p-2 text-center">
                                        {{ lang('No Father Data') }}
                                    </div>
                                </div>

                                <div v-for="(level_2, i) in genealogies.level_2" :key="i" class="level-2 pl-4">
                                    <div class="card pr-4 my-2" :style="'background-color:'+ (level_2.is_current_user ? genealogy_settings.color_self : genealogy_settings.color_level_2) +';'">
                                        <font-awesome-icon icon="genderless" size="2x" :class="'gender '+ level_2.gender"></font-awesome-icon>
                                        <div class="card-body p-2">
                                            <div v-for="(text_content, i) in genealogy_settings.text_contents" :key="i" :class="'text-'+ text_content.align">
                                                <div v-if="level_2[text_content.text_left]" class="text-value">{{ level_2[text_content.text_left] }}</div>
                                                <div v-if="level_2[text_content.text_centre]" class="text-value">{{ level_2[text_content.text_centre] }}</div>
                                                <div v-if="level_2[text_content.text_right]" class="text-value">{{ level_2[text_content.text_right] }}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="level-3 pl-4">
                                        <div v-for="(level_3, i) in level_2.childrens" :key="i" class="card pr-4 my-2" :style="'background-color:'+ (level_3.is_current_user ? genealogy_settings.color_self : genealogy_settings.color_level_3) +';'">
                                            <font-awesome-icon icon="genderless" size="2x" :class="'gender '+ level_3.gender"></font-awesome-icon>
                                            <div class="card-body p-2">
                                                <div v-for="(text_content, i) in genealogy_settings.text_contents" :key="i" :class="'text-'+ text_content.align">
                                                    <div v-if="level_3[text_content.text_left]" class="text-value">{{ level_3[text_content.text_left] }}</div>
                                                    <div v-if="level_3[text_content.text_centre]" class="text-value">{{ level_3[text_content.text_centre] }}</div>
                                                    <div v-if="level_3[text_content.text_right]" class="text-value">{{ level_3[text_content.text_right] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div v-if="!state.loading_resource && genealogies.length == 0" class="text-center py-5">{{ lang('No data available') }}</div> -->
                    </div>
                </div>
            </div>

            <loader v-if="state.loading || state.loading_resource" />
        </main>
    </div>
</template>

<script>
export default {
    props: ['merchant_member_id'],

    data() {
        return {
            genealogies: {},
            genealogy_settings: {},
            merchant: {
                merchant_logo: this.$route.query.merchant_logo_url,
            },

            searching: '',
        }
    },

    methods: {
        getResource() {
            this.$set(this.state, 'loading_resource', true)
            axios.post(this.GLOBAL.API_BASE_URL +"digital-card/"+ this.merchant_member_id + "/genealogy")
                .then(response => {
                    this.genealogies = response.data.genealogy_data
                    this.genealogy_settings = response.data.genealogy_settings
                    this.merchant = response.data.merchant
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading_resource', false))
        },
    },

    created() {
        this.getResource()
    },

    beforeRouteLeave(to, from, next) {
        to.params.member_active_index = this.$route.query.member_active_index
        next()
    }
}
</script>

<style lang="scss" scoped>
    .text-LEFT {
        display: flex;
        div {
            margin-right: 1rem;
        }
    }
    .text-RIGHT {
        display: flex;
        justify-content: right;
        div {
            margin-left: 1rem;
        }
    }
    .text-CENTRE {
        display: flex;
        justify-content: center;
        div {
            margin-left: 1rem;
            margin-right: 1rem;
        }
    }
    .text-JUSTIFY {
        display: flex;
        > div:not(:first-child):not(:last-child) {
            margin: auto;
        }
        > div:first-child {
            margin-right: auto;
        }
        > div:last-child {
            margin-left: auto;
        }
    }

    .card {
        position: relative;
        z-index: 12;

        .gender {
            position: absolute;
            top: 0;
            right: 0.4rem;

            &.Male {
                color: #3490dc;
            }
            &.Female {
                color: #ff94f0;
            }
        }
    }

    .level-2 {
        &:not(:last-child) {
            position: relative;
            &::before {
                content: "";
                z-index: 9;
                position: absolute;
                border-left: 1px solid #c9c9c9;
                height: 100%;
                width: 1rem;
                left: calc(0.5rem + 1px);
                top: 0;
            }
        }
        
        .card {
            z-index: 11;
            &::before {
                content: "";
                z-index: 9;
                position: absolute;
                border-left: 1px solid #c9c9c9;
                border-bottom: 1px solid #c9c9c9;
                border-bottom-left-radius: 0.8rem;
                height: calc(100% + 1.5rem);
                width: 1rem;
                left: -1rem;
                top: calc(-50% - 1.5rem);
            }
        }
    }

    .level-3 {
        .card {
            z-index: 10;
            &::before {
                content: "";
                z-index: 9;
                position: absolute;
                border-left: 1px solid #c9c9c9;
                border-bottom: 1px solid #c9c9c9;
                border-bottom-left-radius: 0.8rem;
                height: calc(100% + 1.5rem);
                width: 1rem;
                left: -1rem;
                top: calc(-50% - 1.5rem);
            }
        }
    }
</style>