<template>
    <div class="single-header">
        <secondary-header title="Payment List" backRoute="default"></secondary-header>

        <main>
            <div class="container select-bills">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="content main-bill-list pb-5">

                            <div class="header">
                                <div class="container">
                                    <div class="row justify-content-center">
                                        <div class="text-right my-3 col-md-10">
                                            {{ lang('Credit Balance') }}: {{ money(wallet_details.wallet_balance) }}
                                            <a href="#" @click.prevent="closeApp('gopay/reload/main')" class="text-info">
                                                <font-awesome-icon icon="plus-circle" size="lg"></font-awesome-icon>
                                            </a >
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="bills row no-gutters">
                                <div class="col-12">
                                    <div class="image merchant" :style="'background: url('+ bill.branch_logo +');'"></div>
                                    <div class="name">
                                        <div>
                                            <div class="branch_name">
                                                {{ bill.customer_name }}
                                            </div>
                                            <div class="branch_name font-weight-bold">
                                                {{ bill.location }}
                                            </div>
                                            <div class="branch_name">
                                                {{ bill.branch_name }}
                                            </div>
                                            <div class="">
                                                <b>{{ lang('Amount Due') }}: {{ money(bill.total_due) }}</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 d-flex">
                                    <table class="fudc-details" width="100%">
                                        <tr>
                                            <td colspan="2">{{ lang('Fast Payment') }}</td>
                                            <td class="text-center">
                                                <span class="info">{{ lang('Fast Pay') }}</span><br>
                                                <input type="number" class="form-control money input-amount" v-model="fast_pay_amount" step="0.01" min="0" disabled>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="py-0">
                                                <a href="#" class="text-primary" data-toggle="modal" data-target="#bill-details-modal"><u>{{ lang('Details') }}</u></a>
                                                <!-- Modal -->
                                                <div class="modal fade" id="bill-details-modal" tabindex="-1" aria-labelledby="bill-details-modal-label" aria-hidden="true">
                                                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                                    <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="bill-details-modal-label">{{ lang('Details') }}</h5>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                        <div class="modal-body">
                                                            <table v-if="bill_details" width="100%">
                                                                <tr>
                                                                    <td class="border-bottom">{{ lang('Description') }}</td>
                                                                    <td class="border-bottom text-right">{{ lang('Inv. Date') }}</td>
                                                                    <td class="border-bottom"></td>
                                                                </tr>
                                                                <template v-for="fudc_detail in bill_details">
                                                                    <tr :key="fudc_detail.fudc_id +'-fudc_name'">
                                                                        <td colspan="3" class="pt-2 pb-0">{{ fudc_detail.fudc_name }}</td>
                                                                    </tr>
                                                                    <tr v-for="(details, i) in fudc_detail.details" :key="fudc_detail.fudc_id +'-2-' + i">
                                                                        <td colspan="2" class="text-right pr-2 py-0">{{ details.desc }}</td>
                                                                        <td class="text-right py-0">{{ money(details.amount) }}</td>
                                                                    </tr>
                                                                    <tr :key="fudc_detail.fudc_id +'-total-amount'">
                                                                        <td>
                                                                            <a v-if="fudc_detail.insurance_policy" href="#" @click.prevent="sharePolicy(fudc_detail.insurance_policy)" class="text-primary" target="_blank">{{ lang('Policy') }}</a>
                                                                        </td>
                                                                        <td class="text-right pr-2 py-0">
                                                                            <a v-if="fudc_detail.insurance_policy" href="#" @click.prevent="sharePolicy(fudc_detail.insurance_policy)" class="text-primary" target="_blank">{{ fudc_detail.insurance_policy.expiry_date }}</a>
                                                                        </td>
                                                                        <td class="text-right font-weight-bold pt-0">{{ lang('Total') }} {{ money(fudc_detail.due) }}</td>
                                                                    </tr>
                                                                </template>
                                                            </table>
                                                            <div v-else-if="state.bill_details_loading" class="text-center py-4">{{ lang('loading...') }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="border-bottom">{{ lang('Description') }}</th>
                                            <th class="border-bottom text-right">{{ lang('Due RM') }}</th>
                                            <th width="33%" class="border-bottom text-center">{{ lang('Amount to pay') }}</th>
                                        </tr>
                                        <template v-if="bill.fudc_amount_dues">
                                            <tr v-for="fudc in bill.fudc_amount_dues" :key="fudc.fudc_id">
                                                <td>{{ fudc.fudc_name }}</td>
                                                <td class="text-right">{{ money(fudc.due, false) }}</td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        class="form-control money"
                                                        v-model="amount_to_pay_fudc[fudc.fudc_id]"
                                                        step="0.01"
                                                        min="0"
                                                        :max="fudc.fudc_name == 'Opening' ? fudc.due : ''"
                                                        :disabled="fudc.due <= 0 && !fudc_can_always_pay.includes(fudc.fudc_name)">
                                                </td>
                                            </tr>
                                            <tr class="font-weight-bold">
                                                <td class="border-top">{{ lang('Total due') }}</td>
                                                <td class="border-top text-right">{{ money(sumBy(bill.fudc_amount_dues, 'due'), false) }}</td>
                                                <td class="border-top"><input type="number" class="total_fudc_amount form-control money" :value="total_fudc_amount" readonly></td>
                                            </tr>
                                        </template>
                                    </table>
                                </div>

                                <div v-if="utility_bills && utility_bills.length > 0" class="col-12 mt-4">
                                    <table width="100%" class="utility-details">
                                        <tr>
                                            <th class="border-bottom px-2" colspan="2">{{ lang('Utility Bills') }}</th>
                                            <th width="33%" class="border-bottom text-center">{{ lang('Amount to pay') }}</th>
                                        </tr>
                                        <template v-for="(utility, i) in utility_bills">
                                            <tr :key="'u1-'+ i" :style="utility_bills_verification[i].is_loading ? 'opacity: 0.6;' : ''">
                                                <td width="23%" rowspan="2"><div class="image merchant" :style="'background: url('+ utility.logo_url +');'"></div></td>
                                                <td>
                                                    <div class="font-weight-bold">{{ utility.name }}</div>
                                                    <div>{{ utility.account_no }}</div>
                                                    <div class="font-weight-bold">
                                                        {{ lang('Due') }}:
                                                        <span v-if="utility_bills_verification[i] && !utility_bills_verification[i].is_loading && utility_bills_verification[i].amount_due != undefined">
                                                            {{ money(utility_bills_verification[i].amount_due) }}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        class="form-control money"
                                                        :class="{'is-invalid': !isValidUtilityAmount(i)}"
                                                        v-model="amount_to_pay_utility[i]"
                                                        step="0.01"
                                                        min="1"
                                                        :disabled="state.loading_utility || utility.is_maintenance || !utility_bills_verification[i] || utility_bills_verification[i].status != 'success'">

                                                    <div v-if="utility_bills_verification[i].amount_settings && utility_bills_verification[i].amount_settings.hints" style="font-size: 0.7rem; line-height: 0.9rem;">
                                                        <div v-for="(hint, i) in utility_bills_verification[i].amount_settings.hints" :key="i" class="mb-1">{{ hint }}</div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr :key="'u2-'+ i">
                                                <td colspan="2">
                                                    <div class="d-flex align-items-end">
                                                        <div style="width: 100%">
                                                            <div v-if="utility_bills_verification[i] && utility_bills_verification[i].is_loading" class="progress" style="height: 5px;">
                                                                <div class="progress-bar progress-bar-striped bg-secondary progress-bar-animated" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div v-else class="text-danger">{{ utility_bills_verification[i].message }}</div>
                                                        </div>
                                                        <div style="min-height: 10px;"></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </table>
                                </div>
                            </div>

                            <float-bottom>
                                <router-link :to="{name: 'bills.payment-method', params: {prev_input: inputs}}">
                                    <button type="button" class="btn btn-primary btn-block" :disabled="!can_submit">
                                        <span
                                            v-if="this.total_amount_to_pay > this.wallet_details.wallet_balance"
                                            class="insifficient-balance"
                                            @click.prevent=""
                                        >
                                            {{ lang('Insufficient balance, please reload.') }}
                                        </span>
                                        {{ lang('Pay Now Total') }}: {{ money(total_amount_to_pay) }}
                                    </button>
                                </router-link>
                            </float-bottom>
                        </div>
                    </div>
                </div>
            </div>
            <loader v-if="state.loading" />
        </main>
    </div>
</template>

<script>


export default {
    props: ['identifier'],

    data() {
        return {
            bill: {},
            fast_pay_amount: '',
            amount_to_pay: {},
            amount_to_pay_fudc: {},
            total_fudc_amount: '',
            fudc_can_always_pay: [
                'Service Charge',
                'Sinking Fund',
            ],
            wallet_details: {},

            bill_details: undefined,

            amount_to_pay_utility: {},
            total_utility_amount: '',
            utility_bill_details: {},

            utility_bills: [],
            utility_bills_verification: [],
        }
    },

    watch: {
        fast_pay_amount: function(newVal) {
            var first_fudc_input_id = ''
            var val = parseFloat(newVal)
            
            // opening amount first
            this.bill.fudc_amount_dues.forEach((fudc) => {
                if(val > 0) {
                    if(!first_fudc_input_id && fudc.fudc_name != 'Opening') {
                        first_fudc_input_id = fudc.fudc_id
                    }
                    if(fudc.opening_not_paid > 0 && val - fudc.opening_not_paid >= 0) {
                        val -= parseFloat(fudc.opening_not_paid)
                        this.$set(this.amount_to_pay_fudc, fudc.fudc_id, this.moneyRounding(fudc.opening_not_paid).toFixed(2))
                    } else {
                        if(fudc.opening_not_paid > 0) {
                            this.$set(this.amount_to_pay_fudc, fudc.fudc_id, this.moneyRounding(val).toFixed(2))
                            val = 0
                        } else {
                            this.$set(this.amount_to_pay_fudc, fudc.fudc_id, '')
                        }
                    }
                }
                else {
                    this.$set(this.amount_to_pay_fudc, fudc.fudc_id, '')
                }
            })

            this.bill.fudc_amount_dues.forEach((fudc) => {
                if(val > 0) {
                    var current_fudc_due = fudc.due - parseFloat(this.amount_to_pay_fudc[fudc.fudc_id] || 0)
                    if(!first_fudc_input_id && fudc.fudc_name != 'Opening') {
                        first_fudc_input_id = fudc.fudc_id
                    }
                    if(current_fudc_due > 0 && val - current_fudc_due >= 0) {
                        val -= parseFloat(current_fudc_due)
                        this.amount_to_pay_fudc[fudc.fudc_id] = this.moneyRounding(parseFloat(this.amount_to_pay_fudc[fudc.fudc_id] || 0) + current_fudc_due).toFixed(2)
                    } else {
                        if(current_fudc_due > 0) {
                            this.amount_to_pay_fudc[fudc.fudc_id] = this.moneyRounding(parseFloat(this.amount_to_pay_fudc[fudc.fudc_id] || 0) + val).toFixed(2)
                            val = 0
                        }
                    }
                }
            })
            if(val > 0 && first_fudc_input_id) {
                this.amount_to_pay_fudc[first_fudc_input_id] = this.moneyRounding(parseFloat(this.amount_to_pay_fudc[first_fudc_input_id] || 0) + val).toFixed(2)
            }
        },

        amount_to_pay_fudc: {
            handler: function(newVal, oldVal) {
                this.total_fudc_amount = 0
                var opening_fudc = this.bill.fudc_amount_dues.find(x => x.fudc_name == 'Opening')

                for (const fudc_id in this.amount_to_pay_fudc) {
                    if(opening_fudc && opening_fudc.fudc_id == fudc_id) {
                        if(this.moneyRounding(this.amount_to_pay_fudc[fudc_id]) > opening_fudc.due) {
                            this.amount_to_pay_fudc[fudc_id] = opening_fudc.due
                        }
                    }
                    if(this.amount_to_pay_fudc[fudc_id]) {
                        this.total_fudc_amount += this.moneyRounding(this.amount_to_pay_fudc[fudc_id])
                    }
                }
                this.total_fudc_amount = this.moneyRounding(this.total_fudc_amount).toFixed(2)
                this.amount_to_pay = {[this.identifier]: this.total_fudc_amount}
            },

            deep: true
        },

        amount_to_pay_utility: {
            handler: function(newVal, oldVal) {
                this.total_utility_amount = 0
                this.utility_bill_details = {}

                for (const index in this.amount_to_pay_utility) {
                    if(this.amount_to_pay_utility[index]) {
                        this.total_utility_amount += this.moneyRounding(this.amount_to_pay_utility[index])
                        this.utility_bill_details[index] = {
                            id: this.utility_bills[index].id,
                            uuid: this.utility_bills_verification[index].uuid,
                            account_no: this.utility_bills[index].account_no,
                            type: this.utility_bills[index].type,
                            amount: this.moneyRounding(this.amount_to_pay_utility[index]),
                            identifier: this.identifier,
                            name: this.utility_bills[index].name,
                        }
                    }
                }
                this.total_utility_amount = this.total_utility_amount
            },

            deep: true
        },
    },

    computed: {
        total_amount_to_pay: function() {
            return this.total_fudc_amount + this.total_utility_amount
        },

        inputs: function() {
            return {
                amount_to_pay: this.amount_to_pay,
                amount_to_pay_fudc: {[this.identifier]: this.amount_to_pay_fudc},
                total_fudc_amount: this.total_fudc_amount,
                amount_to_pay_details: {[this.identifier]: {
                    branch_name: this.bill.branch_name,
                    location: this.bill.location,
                    customer_name: this.bill.customer_name,
                    amount: this.total_fudc_amount
                }},
                // utility
                amount_to_pay_utility: this.amount_to_pay_utility,
                total_utility_amount: this.total_utility_amount,
                utility_bill_details: this.utility_bill_details,

                total_amount_to_pay: this.total_amount_to_pay,
            }
        },

        can_submit: function() {
            return this.total_amount_to_pay > 0
                && this.wallet_details.wallet_balance >= this.total_amount_to_pay
                && !this.utility_bills_verification.find(u => u.is_valid === false);
        },
    },

    methods: {
        getBills() {
            this.$set(this.state, 'loading', true)
            axios.post(this.GLOBAL.API_BASE_URL +"bill-single", {pending_only: true, identifier: this.identifier})
                .then(response => {
                    this.bill = response.data
                })
                .catch(error => {
                    if(error.response.status == 403) {
                        Swal.fire({
                            type: 'error',
                            text: error.response.data.message,
                            timer: 10000,
                            onClose: () => {
                                this.$router.go(-1);
                            }
                        })
                    } else {
                        defaultErrorManagement(error.response)
                    }
                }) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        },

        loadUtilityDetails() {
            this.$set(this.state, 'loading_utility', true)
            axios.post(this.GLOBAL.API_BASE_URL +"utility-bills", {identifier: this.identifier})
                .then(response => {
                    this.utility_bills = response.data
                    this.verifyUtilityBills(this.utility_bills)
                })
                .catch(error => defaultErrorManagement(error.response))
                .finally(() => this.$set(this.state, 'loading_utility', false))
        },

        verifyUtilityBills(utility_bills) {
            utility_bills.forEach((utility_bill, index) => {
                this.$set(this.utility_bills_verification, index, {})
                this.$set(this.utility_bills_verification[index], 'is_loading', true)
                axios.post(this.GLOBAL.API_BASE_URL +"utility-bills/verify", utility_bill)
                    .then(response => {
                        this.$set(this.utility_bills_verification[index], 'status', response.data.status)
                        this.$set(this.utility_bills_verification[index], 'amount_due', response.data.amount_due)
                        this.$set(this.utility_bills_verification[index], 'amount_settings', response.data.amount_settings)
                        if(response.data.status == 'success') {
                            this.$set(this.utility_bills_verification[index], 'uuid', response.data.uuid)
                        } else {
                            this.$set(this.utility_bills_verification[index], 'message', response.data.message)
                        }
                    })
                    .catch(error => defaultErrorManagement(error.response))
                    .finally(() => this.utility_bills_verification[index].is_loading = false)
            })
        },

        isValidUtilityAmount(index) {
            var is_valid = true;
            var amount = this.amount_to_pay_utility[index]
            var amount_setting = this.utility_bills_verification[index].amount_settings
            if(amount && amount_setting) {
                if(amount_setting.type == 'SELECTION') {
                    is_valid = is_valid && amount_setting.selection.includes(amount)
                }
                else if(amount_setting.type == 'INPUT') {
                    if(amount_setting.min) {
                        is_valid = is_valid && amount >= amount_setting.min
                    }
                    if(amount_setting.max) {
                        is_valid = is_valid && amount <= amount_setting.max
                    }
                    if(amount_setting.decimal === false) {
                        is_valid = is_valid && Number.isInteger(Number(amount))
                    }
                }
            }
            this.$set(this.utility_bills_verification[index], 'is_valid', is_valid)
            return is_valid
        },

        getBillDetails() {
            this.$set(this.state, 'bill_details_loading', true)
            axios.post(this.GLOBAL.API_BASE_URL +"bill-single-details", {identifier: this.identifier, fudc_amount_dues: this.bill.fudc_amount_dues})
                .then(response => {
                    this.bill_details = response.data
                })
                .catch(error => {
                    if(error.response.status == 403) {
                        Swal.fire({
                            type: 'error',
                            text: error.response.data.message,
                            timer: 10000,
                            onClose: () => {
                                this.$router.go(-1);
                            }
                        })
                    } else {
                        defaultErrorManagement(error.response)
                    }
                }) //to catch the errors if any
                .finally(() => this.$set(this.state, 'bill_details_loading', false))
        },

        getCustomerWalletDetails() {
            this.$set(this.state, 'loading_wallet', true)
            axios.get(this.GLOBAL.API_BASE_URL +"customer/wallet-details")
                .then((response) => {
                    this.wallet_details = response.data;
                })
                .finally(() => this.$set(this.state, 'loading_wallet', false));
        },

        sumBy(obj, key) {
            return _.sumBy(obj, key)
        },

        sharePolicy(insurance_policy) {
            if(this.isNative()) {
                window.ReactNativeWebView.postMessage("SHARE|type=gobills-pdf,resource=insurance_policy,resource_id="+ insurance_policy.id +",api_token="+ localStorage.token)
            }
            else if('share' in window.navigator) {
                this.$set(this.state, 'loading_share_pdf', true)
                axios.post(this.GLOBAL.API_BASE_URL + "pdf", {
                        resource: 'insurance_policy',
                        resource_id: insurance_policy.id,
                    },
                    {
                        responseType: 'blob',
                    }
                    )
                    .then((response) => {
                        var file = new File([response.data], insurance_policy.expiry_date +".pdf", {type: "application/pdf"})
                        var files = [file]

                        if(navigator.canShare({ title: insurance_policy.expiry_date, files })) {
                            navigator.share({ title: insurance_policy.expiry_date, files })
                                .catch((err) => console.log(err))
                        }
                    })
                    .catch(error=>{
                        console.log(error)
                        error.response.data.text()
                            .then((data) => {
                                error.response.data = JSON.parse(data)
                                defaultErrorManagement(error.response)
                            })
                    })
                    .finally(() => this.$set(this.state, 'loading_share_pdf', false))
            }
        },
    },

    created() {
        this.getBills()
        this.loadUtilityDetails()
        this.getCustomerWalletDetails()
    },

    mounted() {
        var _this = this
        $(function() {
            $('body').on('change blur', '.money', function() {
                if(this.value) {
                    $(this).val(_this.moneyRounding(this.value).toFixed(2))
                    
                    // Create native event
                    const event = new Event('input', { bubbles: true });

                    // Dispatch the event on "native" element
                    $(this).get(0).dispatchEvent(event);
                }
            })

            $('#bill-details-modal').on('show.bs.modal', function() {
                _this.getBillDetails()
            })
        })
    },

    beforeRouteEnter (to, from, next) {
        if(to.params.identifier == undefined) {
            next({name: 'bills', replace: true})
        }
        else {
            next()
        }
    },
}
</script>

<style lang="scss" scoped>
.button {
    font-size: 0.85rem;
    padding: 3px;

    .info {
        font-size: 0.7rem;
    }
}

.main-bill-list {
    .header {
        position: fixed;
        background-color: #ffffff;
        left: 0;
        right: 0;
        z-index: 1;
        border-bottom: 1px solid #d6d6d6;
        height: 3.7rem;
    }

    .bills {
        padding-top: 3.1rem;
    }
}

.select-bills {
    .bills {
        .merchant {
            min-width: 25%;
        }
        .name {
            min-width: 50%;
        }
    }
}

.float-bottom {
    button {
        position: relative;
    }
}
.insifficient-balance {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: -0.1rem;
    background-color: #ffffff00;
    color: red;
    text-align: center;
    font-weight: bold;
    font-size: 0.9rem;
}

.input-amount {
    &.readonly {
        background-color: #f4f4f4;
    }
}

.fudc-details,
.utility-details {
    td,
    th {
        padding: 0.2rem 0.5rem;
    }
}

.utility-details {
    td {
        font-size: 90%;
    }

    .image {
        width: 100% !important;
        max-width: 100% !important;
        border: unset !important;
    }
}

.money {
    text-align: right;
}
</style>