<template>
    <div class="single-header">
        <nav>
        <div>
            <div class="top-logo text-center font-weight-bold text-white bg-primary">
                <div class="back-button">
                    <a href="#" @click.prevent="closeApp()" class="text-white"><font-awesome-icon icon="chevron-left" size="lg"></font-awesome-icon></a>
                </div>
                <div class="title">
                    {{ lang('Membership') }}
                </div>
            </div>
        </div>
    </nav>

        <main>
            <div class="container">
                <div class="row justify-content-center digital-card">
                    <div class="col-lg-10">
                        <div class="owl-carousel owl-theme">
                            <div v-for="(member, i) in merchant_members" class="item" :key="i">
                                <div id="digital-card" class="text-center m-auto" :style="'background-color:'+ cardSetting(member).colors.inner +';'">
                                    <img :src="member.merchant.merchant_logo" alt="" class="logo">

                                    <div class="merchant_name2" :style="'color:'+ cardSetting(member).colors.merchant_name2">{{ member.merchant.name2 }}</div>
                                    <div class="merchant_name1" :style="'color:'+ cardSetting(member).colors.merchant_name1">{{ member.merchant.name }}</div>

                                    <img :src="member.member_photo" alt="" class="profile-picture">

                                    <div class="member_name2" :style="'color:'+ cardSetting(member).colors.member_name2">{{ member.name2 }}</div>
                                    <div class="member_name1" :style="'color:'+ cardSetting(member).colors.member_name1">{{ member.name }}</div>

                                    <div class="header" :style="'color:'+ cardSetting(member).colors.header_text +'; background-color:'+ cardSetting(member).colors.header +';'">{{ lang(cardSetting(member).others.header_text) }}</div>

                                    <div v-for="content in cardSetting(member).text_contents" class="text-content" :style="'color:'+ cardSetting(member).colors.text_content" :key="content.detail_field">
                                        {{ content.detail_field_rename ? content.detail_field_rename : detail_field_list[content.detail_field] }}: {{ member[content.detail_field] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="merchant_members.length == 0 && !(state.loading || state.loading_digital_card)" class="no-digital-card">
                            <div>
                                <div class="imoji">🤭</div>
                                <div class="text">{{ lang('You have no membership to show!') }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <button v-show="!state.loading && merchant_members.length > 0 && merchant_members[member_active_index].is_has_genealogy" :disabled="state.loading_share_pdf" :class="{disabled: state.loading_share_pdf}" class="share-button t-top genealogy-button" @click="openGenealogy()">
                <font-awesome-icon icon="sitemap" size="2x"></font-awesome-icon>
            </button>

            <button v-show="!state.loading && merchant_members.length > 0" :disabled="state.loading_share_pdf" :class="{disabled: state.loading_share_pdf}" class="share-button t1 business-button" @click="openBusiness()">
                <font-awesome-icon icon="handshake" size="2x"></font-awesome-icon>
            </button>

            <button v-show="!state.loading && merchant_members.length > 0" :disabled="state.loading_share_pdf" :class="{disabled: state.loading_share_pdf}" class="share-button committee-button" @click="openCommittee()">
                <font-awesome-icon icon="users" size="2x"></font-awesome-icon>
            </button>

            <!-- <button v-show="!state.loading && merchant_members.length > 0" :disabled="state.loading_share_pdf" :class="{disabled: state.loading_share_pdf}" class="share-button bit-left" @click="shareResource">
                <font-awesome-icon icon="share-alt" size="2x"></font-awesome-icon>
            </button> -->

            <loader v-if="state.loading || state.loading_digital_card" />
        </main>
    </div>
</template>

<script>
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import 'owl.carousel';

export default {
    data() {
        return {
            merchant_members: [],
            digital_card_settings: {},
            detail_field_list: {},
            member_active_index: this.$route.params.member_active_index ? this.$route.params.member_active_index : 0,
        }
    },

    methods: {
        getDigitalCard() {
            this.$set(this.state, 'loading_digital_card', true)
            axios.post(this.GLOBAL.API_BASE_URL +"digital-card")
                .then(response => {
                    this.merchant_members = response.data.merchant_members
                    this.digital_card_settings = response.data.digital_card_settings
                    this.detail_field_list = response.data.detail_field_list
                    this.$nextTick(() => {
                        this.initianteCarousel()
                    })
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading_digital_card', false))
        },

        openGenealogy() {
            this.$router.push({ name:'digital-card.genealogy', params: {merchant_member_id: this.merchant_members[this.member_active_index].id}, query: {merchant_logo_url: this.merchant_members[this.member_active_index].merchant.merchant_logo, member_active_index: this.member_active_index} })
        },

        openBusiness() {
            this.$router.push({ name:'digital-card.business', params: {merchant_member_id: this.merchant_members[this.member_active_index].id}, query: {merchant_logo_url: this.merchant_members[this.member_active_index].merchant.merchant_logo, member_active_index: this.member_active_index} })
        },

        openCommittee() {
            this.$router.push({ name:'digital-card.committee-profile', params: {merchant_member_id: this.merchant_members[this.member_active_index].id}, query: {member_active_index: this.member_active_index} })
        },

        initianteCarousel() {
            $('.owl-carousel').owlCarousel({
                loop:false,
                nav: true,
                margin:10,
                startPosition: this.member_active_index,
                responsive:{
                    0:{
                        items:1
                    },
                }
            });
            $('.owl-carousel').owlCarousel('refresh');

            $('.owl-carousel').on('changed.owl.carousel', (event) => {
                this.member_active_index = event.item.index
            })
        },

        cardSetting(member) {
            if(this.digital_card_settings[member.merchant_ref_no +'_'+ member.sc_id]) {
                return this.digital_card_settings[member.merchant_ref_no +'_'+ member.sc_id]
            } else {
                return {
                    colors: {
                        merchant_name1: '#072060',
                        merchant_name2: '#ed7c31',
                        member_name1: '#072060',
                        member_name2: '#072060',
                        inner: '#fef2cc',
                        header: '#f4b183',
                        header_text: '#072060',
                        text_content: '#072060',
                    },
                    text_contents: {},
                    others: {},
                }
            }
        },

        shareResource() {
            // wip
        },
    },

    created() {
        this.$set(this.state, 'loading', true)
        if(this.$route.query.token) {
            localStorage.token = this.$route.query.token
        }
        axios.post(this.GLOBAL.API_BASE_URL + "customer-details", {
                token: localStorage.token
            })
            .then(response => {
                if(response.data.status == 'OK') {
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ localStorage.token;
                    this.getDigitalCard()
                } else {
                    Swal.fire({
                        text: 'Session expired',
                        timer: 5000,
                        onClose: () => {
                            window.ReactNativeWebView.postMessage("WINDOW_CLOSED")
                        }
                    })
                }
            })
            .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
            .finally(() => this.$set(this.state, 'loading', false))
    },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
.digital-card {
    .owl-carousel {
        padding-top: 2.5rem;
        padding-bottom: 1rem;

        .item {
            padding-top: 4rem;
            position: relative;
        }

        .owl-dots {
            position: absolute;
            top: 0.4rem;
            left: 0;
            right: 0;

            button:focus {
                outline:0;
            }
        }

        .owl-nav {
            button {
                &:focus {
                    outline:0;
                }
                &:hover {
                    background: unset;
                    color: unset;
                }
                &.disabled {
                    background: unset !important;
                    color: #e6e6e600 !important
                }
            }

            .owl-prev,
            .owl-next {
                position: absolute;
                font-size: 10rem;
                line-height: 10rem;
                transform: scaleX(0.7);
                top: calc(50% - 5rem);
                color: #b2b2b2;
            }
            .owl-prev {
                left: -1.5rem;
            }
            .owl-next {
                right: -1.5rem;
            }
        }
    }
}

#digital-card {
    margin-top: 5rem;
    height: 30rem;
    width: 18rem;
    max-width: calc(100vw - 4rem);
    border: 1px solid black;
    border-radius: 1.5rem;
    position: relative;

    img {
        width: unset;
        display: inline;
    }

    .logo {
        position: absolute;
        width: 7rem;
        left: calc(50% - 3.5rem);
        top: -3.5rem;
    }

    .merchant_name2 {
        margin-top: 4rem;
        font-weight: bold;
        font-size: 1.1rem;
    }
    .merchant_name1 {
        font-weight: bold;
        font-size: 0.8rem;
        margin-top: -0.4rem;
    }

    .profile-picture {
        height: 6.5rem;
        width: 4.75rem;
        border: 1.5px solid #5b9bd5;
        margin-top: 0.5rem;
    }

    .member_name2 {
        margin-top: 0.3rem;
        font-weight: bold;
        font-size: 1.1rem;
    }
    .member_name1 {
        font-size: 0.9rem;
        margin-top: -0.2rem;
    }

    .header {
        margin-top: 0.3rem;
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.8rem;
        margin-bottom: 0.3rem;
    }

    .text-content {
        text-align: left;
        padding: 0 0.5rem;
        font-size: 0.9rem;
        line-height: 1.2rem;
    }
}

.no-digital-card {
    text-align: center;
    height: calc(100vh - 20rem);
    display: flex;
    > * {
        margin: auto;
    }

    .imoji {
        font-size: 4rem;
    }
    .text {
        font-size: 1.4rem;
    }
}

.genealogy-button {
    padding-bottom: 0.2rem !important;
    font-size: 85%;
    background-color: #86c5f7 !important;
}

.committee-button {
    padding-bottom: 0.2rem !important;
    font-size: 85%;
    background-color: #86c5f7 !important;
}

.business-button {
    padding-top: 0.2rem !important;
    font-size: 90%;
    color: #2c8fde !important;
    border: 1px solid #2c8fde !important;
    background-color: #ffffff !important;
}
</style>