<template>
    <div class="single-header">
        <secondary-header title="GoBills" backRoute="default"></secondary-header>

        <main>
            <div class="container select-bills">
                <div class="row justify-content-center">
                    <div class="text-right mb-3 pt-2 col-md-10">
                        {{ lang('Credit Balance') }}: {{ money(wallet_details.wallet_balance) }}
                        <a href="#" @click.prevent="closeApp('gopay/reload/main')" class="text-info">
                            <font-awesome-icon icon="plus-circle" size="lg"></font-awesome-icon>
                        </a>
                    </div>
                </div>

                <div v-if="!state.loading_resource && resource.id" class="pt-5">
                    <table width="100%">
                        <tr>
                            <td width="1%" class="text-right ws-pre">{{ lang('State') }}</td>
                            <td width="1%" class="pr-3" >:</td>
                            <td>{{ resource.state }}</td>
                        </tr>
                        <tr>
                            <td class="text-right ws-pre">{{ lang('City') }}</td>
                            <td class="pr-3" >:</td>
                            <td>{{ resource.city }}</td>
                        </tr>
                        <tr>
                            <td class="text-right ws-pre">{{ lang('Property') }}</td>
                            <td class="pr-3" >:</td>
                            <td>{{ resource.property }}</td>
                        </tr>
                        <tr>
                            <td class="text-right ws-pre">{{ lang('Location') }}</td>
                            <td class="pr-3" >:</td>
                            <td>{{ resource.location }}</td>
                        </tr>
                        <tr>
                            <td class="text-right ws-pre">{{ lang('Service Charge') }}</td>
                            <td class="pr-3" >:</td>
                            <td>{{ resource.service_charge }}</td>
                        </tr>
                        <tr>
                            <td class="text-right ws-pre">{{ lang('Sinking Fund') }}</td>
                            <td class="pr-3" >:</td>
                            <td>{{ resource.sinking_fund }}</td>
                        </tr>
                        <tr>
                            <td class="text-right ws-pre">{{ lang('Block') }}</td>
                            <td class="pr-3" >:</td>
                            <td>{{ resource.block }}</td>
                        </tr>
                        <tr>
                            <td class="text-right ws-pre">{{ lang('Level') }}</td>
                            <td class="pr-3" >:</td>
                            <td>{{ resource.level }}</td>
                        </tr>
                        <tr>
                            <td class="text-right ws-pre">{{ lang('Type') }}</td>
                            <td class="pr-3" >:</td>
                            <td>{{ resource.type }}</td>
                        </tr>
                        <tr>
                            <td class="text-right ws-pre">{{ lang('Owner') }}</td>
                            <td class="pr-3" >:</td>
                            <td>{{ resource.owner }}</td>
                        </tr>
                        <tr>
                            <td class="text-right ws-pre">{{ lang('Amount Due') }}</td>
                            <td class="pr-3" >:</td>
                            <td>{{ resource.amount_due }}</td>
                        </tr>
                    </table>

                    <div class="text-center pt-5 font-weight-bold">
                        <div>{{ lang('This report is up to date') }}</div>
                        <div>{{ lang('on') }} {{ dateUi(resource.report_date, 'DD/MM/YYYY') }} {{ lang('at') }} {{ dateUi(resource.report_date, 'HH:mm:ss') }}</div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    props: ['filters'],

    data() {
        return {
            current_date: moment(),
            wallet_details: {},
            resource: {},
        }
    },

    methods: {
        getCustomerWalletDetails() {
            this.$set(this.state, 'loading_wallet', true)
            axios.get(this.GLOBAL.API_BASE_URL +"customer/wallet-details")
                .then((response) => {
                    this.wallet_details = response.data;
                })
                .finally(() => this.$set(this.state, 'loading_wallet', false));
        },

        getResource() {
            this.$set(this.state, 'loading_resource', true)
            axios.post(this.GLOBAL.API_BASE_URL +"report/data", this.filters)
                .then((response) => {
                    this.resource = response.data;
                })
                .finally(() => this.$set(this.state, 'loading_resource', false));
        },
    },

    created() {
        this.getCustomerWalletDetails()
        this.getResource()
    },

    beforeRouteEnter (to, from, next) {
        if(to.params.filters == undefined) {
            next({name: 'report', replace: true})
        }
        else {
            next()
        }
    },
}
</script>
