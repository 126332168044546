<template>
    <div class="einvoice-detail">
        <div>
            <div class="edit-detail">
                <a href="#" @click.prevent="closeApp('profile/einvoice')">
                    <font-awesome-icon
                        icon="edit"
                        size="lg"
                        class="text-primary"
                    ></font-awesome-icon>
                </a>
            </div>
            <div>{{ lang('e-Invoice') }}:</div>
            <div>{{ einvoice_detail.name }}</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            einvoice_detail: {},
        }
    },

    methods: {
        getEinvoiceDetail() {
            this.$set(this.state, 'loading', true)
            axios.get(this.GLOBAL.API_BASE_URL+"customer/einvoice-details")
                .then(response => {
                    this.einvoice_detail = response.data
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => {
                    this.$set(this.state, 'loading', false)
                })
        },
    },

    created() {
        this.getEinvoiceDetail()
    },
}
</script>

<style scoped lang="scss">
.einvoice-detail {
    position: fixed;
    width: 100vw;
    left: 0;
    bottom: 0;
    z-index: 2;
    padding: 0.5rem;
    
    .edit-detail {
        position: absolute;
        top: 0.8rem;
        right: 0.8rem;
    }
    > * {
        background-color: #bdd7ee;
        padding: 0.2rem 1rem;
        border-radius: 0.2rem;
        box-shadow: 0 3px 5px -1px rgba(0,0,0,.2),0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.12);
        min-height: 3.7rem;
    }
}
</style>
