<template>
    <div class="select-bills">
        <div :class="{ 'is-loading': state.loading }" class="main-bill-list pb-2">
            <div class="header">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="text-right mb-3 pt-2 col-md-10">
                            {{ lang('Credit Balance') }}: {{ money(wallet_details.wallet_balance) }}
                            <!-- <a href="#" @click.prevent="closeApp('gopay/reload/main')" class="text-info"> -->
                            <a :href="GLOBAL.MAIN_GONET_URL + 'gopay/reload/main'" class="text-info">
                                <font-awesome-icon icon="plus-circle" size="lg"></font-awesome-icon>
                            </a >
                        </div>
                        <div class="font-weight-bold mb-2 col-md-10">
                            <router-link class="btn btn-primary btn-sm float-right" to="/report">{{ lang('Report') }}</router-link>
                            <div>
                                {{ lang('Mybills Total Due') }} : {{ money(total_current_due) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bills row no-gutters">
                <div v-for="bill in bills" class="col-lg-6" @click="viewDetails(bill)" :key="bill.identifier">
                    <div class="image merchant" :style="'background: url('+ bill.branch_logo +');'"></div>
                    <div class="name">
                        <div>
                            <div class="branch_name">
                                {{ bill.customer_name }}
                            </div>
                            <div class="branch_name font-weight-bold">
                                {{ bill.location }}
                            </div>
                            <div class="branch_name">
                                {{ bill.branch_name }}
                            </div>
                            <div class="font-weight-bold">
                                <b>{{ lang('Amount Due') }}: <span v-if="bill.total_due < 0">-</span> {{ money(Math.abs(bill.total_due)) }}</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="bills.length == 0 && state.loading" class="loader">
                {{ lang('loading...') }}
            </div>
            <div v-else-if="false" class="text-center info-color py-4">
                {{ lang('No available location') }}
            </div>
        </div>

        <!-- <float-bottom>
            <a href="#" @click.prevent="payNow()">
                <button type="button" class="btn btn-primary btn-block" :disabled="state.loading_wallet || bills.length <= 0">{{ lang('Multi Payment') }}</button>
            </a>
        </float-bottom> -->
    </div>
</template>

<script>

export default {
    data() {
        return {
            bills: [],
            total_current_due: 0,
            wallet_details: {},
        }
    },

    methods: {
        getMerchant() {
            this.$set(this.state, 'loading', true)
            axios.post(this.GLOBAL.API_BASE_URL +"bills")
                .then(response => {
                    this.bills = response.data

                    this.totalCurrentDue(response.data)
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        },

        totalCurrentDue(bills) {
            for (var i = 0; i < bills.length; i++) {
                this.total_current_due += parseFloat(bills[i].total_due)
            }
        },

        viewDetails(bill) {
            this.$router.push({ name: 'bills.details', params: {bill:bill}})
        },

        getCustomerWalletDetails() {
            this.$set(this.state, 'loading_wallet', true)
            axios.get(this.GLOBAL.API_BASE_URL +"customer/wallet-details")
                .then((response) => {
                    this.wallet_details = response.data;
                })
                .finally(() => this.$set(this.state, 'loading_wallet', false));
        },

        payNow() {
            if(this.wallet_details.has_pin) {
                this.$router.push({name: 'bills.payment-list'})
            } else {
                Swal.fire({
                    text: this.lang('Please set your Transaction Pin before payment'),
                    timer: 5000,
                    onClose: () => {
                        this.closeApp('gopay/transaction-pin-setup')
                    }
                })
            }
        }
    },

    created() {
        this.getMerchant()
        this.getCustomerWalletDetails()
    },

    beforeRouteLeave(to, from, next) {
        if(to.name == 'auth' || to.name == 'bills.payment-status-gopay' || to.name == 'bills.payment-status-fpx') {
            this.closeApp()
        } else {
            next()
        }
    }
}
</script>

<style lang="scss" scoped>
.main-bill-list {
    .header {
        position: fixed;
        background-color: #ffffff;
        left: 0;
        right: 0;
        z-index: 1;
        border-bottom: 1px solid #d6d6d6;
        height: 5.4rem;
    }

    .bills {
        padding-top: 4.8rem;
    }
}
</style>